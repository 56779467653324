<template>
    <v-form>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <h1>{{item['@id']}}</h1>
                </v-col>
            </v-row>
            <v-row>
                <media-object v-model="item" label="votre fichier" is-image max-width="30vw" max-height="20vh"/>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import has from 'lodash/has';
    import {validationMixin} from 'vuelidate';
    import {required} from 'vuelidate/lib/validators';
    import {mapActions} from 'vuex';
    import {mapFields} from 'vuex-map-fields';
    import MediaObject from "../MediaObject";


    export default {
        name: 'MediaObjectForm',
        components: {MediaObject},
        mixins: [validationMixin],
        props: {
            values: {
                type: Object,
                required: true
            },

            errors: {
                type: Object,
                default: () => {
                }
            },

            initialValues: {
                type: Object,
                default: () => {
                }
            }
        },
        mounted() {
        },
        data() {
            return {}
        },
        computed: {

            // eslint-disable-next-line
            item() {
                return this.initialValues || this.values;
            },
            violations() {
                return this.errors || {};
            }
        },
        methods: {
        },
        validations: {
            item: {}
        }
    };
</script>
